import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-knockout.svg'
import phone from '../img/social/phone.svg'
import line from '../img/social/line.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter mt-6">
        <div className="content has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-narrow">
                <img
                  src={logo}
                  alt="Starlight Property"
                  style={{ width: '100px', height: '100px' }}
                />
              </div>
              <div className="column is-narrow">
                <div className="content">
                  <ul>
                    <li>
                      <Link className="has-text-white-ter" to="/property">
                        อสังหาฯ
                      </Link>
                    </li>
                    <li>
                      <Link className="has-text-white-ter" to="/contact">
                        ติตต่อเรา
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column social">
                <ul className="social-list has-margin-bottom-50">
                  <li>
                    <a title="phone" href="tel:0966566000">
                      <img
                        className="social-image"
                        src={phone}
                        alt="Phone"
                        style={{ width: '1.5em', height: '1.5em' }}
                      />
                      096 656 6000
                    </a>
                  </li>
                  <li>
                    <a title="line" href="https://line.me/ti/p/~@starlightproperty#~" target="_blank">
                      <img
                        className="social-image"
                        src={line}
                        alt="Line"
                        style={{ width: '1.5em', height: '1.5em' }}
                      />
                      @starlightproperty
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <small>
                  &copy; Starlight Property Group
                </small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
