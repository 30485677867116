import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../scss/icons.sass'
import '../scss/all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import { addBackToTop } from 'vanilla-back-to-top'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()

  useEffect(() => {
    if (!document.getElementById('back-to-top')) {
      addBackToTop()
    }
  })

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${withPrefix('/')}img/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${withPrefix('/')}img/favicon-16x16.png`} />
        <link rel="manifest" href={`${withPrefix('/')}img/site.webmanifest`} />
        <link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#0d2138" />
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no" />
        <meta name="msapplication-TileColor" content="#0d2138" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix('/')}img/og-image.jpg`} />
        <meta property="fb:app_id" content="1353527818354308" />
      </Helmet>
      <Navbar />
      <main>
        <div>{children}</div>
      </main>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
