import React from 'react'
import Headroom from 'react-headroom'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {

    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {

        // set the class in state for the navbar accordingly
        if (this.state.active) {

          this.setState({
            navBarActiveClass: 'is-active',
          })

          document.getElementById('gatsby-focus-wrapper').style.height = '100%';
          document.documentElement.style.overflow = 'hidden';

        } else {

          this.setState({
            navBarActiveClass: '',
          })

          document.getElementById('gatsby-focus-wrapper').style.height = 'auto';
          document.documentElement.style.overflow = 'auto';

        }
      }
    )
  }

  onLinkClick = () => {

    this.setState({
      navBarActiveClass: '',
    })

    document.getElementById('gatsby-focus-wrapper').style.height = 'auto';
    document.documentElement.style.overflow = 'auto';

  }

  render() {
    return (
      <Headroom
        style={{
          zIndex: 1000
        }}
      >
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo" onClick={() => this.onLinkClick()}>
                <img className="logo" src={logo} alt="Starlight Property" />
              </Link>
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-end">
                <div className="navbar-start">
                  <Link className="navbar-item" to="/property" onClick={() => this.onLinkClick()}>
                    อสังหาริมทรัพย์
                  </Link>
                  <Link className="navbar-item" to="/contact" onClick={() => this.onLinkClick()}>
                    ติดต่อเรา
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Headroom>
    )
  }
}

export default Navbar
